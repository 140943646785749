<template>
  <div class="reg-main-container">
    <h2 class="reg-notice">请选择注册账号类型</h2>
    <div class="select-container">
      <div class="type-box" @click="$emit('chooseRegType', 1)">
        <img src="@/assets/images/department.png">
        <div class="desc">
          <h1 class="desc-title">法人</h1>
          <h2 class="desc-content">供企业和组织注册使用，需提交企业基本信息</h2>
        </div>
      </div>
      <div class="type-box" @click="$emit('chooseRegType', 2)">
        <img src="@/assets/images/person.png">
        <div class="desc">
          <h1 class="desc-title">自然人</h1>
          <h2 class="desc-content">自然人是单个人以个人本身作为民事主体注册使用</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopNav",
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
  .reg-main-container {
    width: 100%;
    margin-top: 28px;
    .reg-notice {
      width: 100%;
      margin-left: -25px;
      font-size: 20px;
      color: #999;
    }
    .select-container {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      .type-box {
        box-sizing: border-box;
        width: 340px;
        height: 200px;
        padding: 0 40px;
        border-radius: 6px;
        background-color: #FFF;
        box-shadow: 0 0 8px 3px rgba(0,0,0,0.1);
        transition: box-shadow linear 0.1s;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          box-shadow: 0 0 4px 3px rgba(20,153,246,0.5);
        }
        img {
          width: 100px;
          height: 100px;
        }
        .desc {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .desc-title {
            font-size: 20px;
            color: $base;
            margin-bottom: 10px;
          }
          .desc-content {
            font-size: 14px;
            color: #787878;
          }
        }
      }
    }
  }
</style>
