import Vue from 'vue';
import App from './App.vue';
import VueRouter from "vue-router";
import router from './router';
import store from './store';

//全局组件-三级联动
/*import TypeNav from '@/components/TypeNav';
import Carousel from '@/components/Carousel';
import Pagination from '@/components/Pagination';*/
// import {Button, MessageBox} from "element-ui";
import Element from 'element-ui'
// import '@/assets/styles/element-variables.scss'
Vue.use(Element)
/*Vue.component(TypeNav.name, TypeNav);
Vue.component(Carousel.name, Carousel);
Vue.component(Pagination.name, Pagination);*/
// Vue.component(Button.name, Button);
//ElementUI注册组件的时候，还有一种写法，挂在原型上
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;

// import 'swiper/css/swiper.min.css';
//统一引入
import './assets/iconfont/iconfont.css';
import * as API from '@/api';
import VueLazyload from "vue-lazyload";
import atm from '@/assets/lazy.gif';

//引入自定义插件
import myPlugin from "@/plugins/myPlugin";
// 公共组件
import ImageUpload from "@/components/ImageUpload";
import Pagination from "@/components/Pagination";
// 全局组件挂载
Vue.component("ImageUpload", ImageUpload);
Vue.component("Pagination", Pagination);
Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueLazyload, {
  //懒加载默认的图片
  loading:atm,
});

//引入表单校验插件
import "@/plugins/validate";

new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this;
    Vue.prototype.$API = API;
  }
}).$mount('#app')
