// import Search from "@/pages/Search";
import Login from "@/pages/Login";
import Register from "@/pages/Register";
import Privacy from "@/pages/Login/privacy.vue";
import EventPage from '@/pages/events/index.vue';

/*const home = () => {
    return import('@/pages/Home');
}*/

export default [{
    path: '/home',
    component: () => import('@/pages/Home'), //懒加载，更加高效
    name: 'home',
    meta: {
        show: true
    }
},
    {
        path: '/personal',
        component: () => import('@/pages/Personal'), //懒加载，更加高效
        name: 'personal',
        meta: {
            show: true
        }
    },
    {
        path: '/list',
        component: () => import('@/pages/List'), //懒加载，更加高效
        name: 'list',
        meta: {
            show: true
        }
    },
    {
        path: '/wxLogin',
        component: () => import('@/pages/wxLogin'), //懒加载，更加高效
        name: 'wxLogin',
        meta: {
            show: true
        }
    },
    {
        path: '/login/:clientId',
        component: Login,
        meta: {
            show: false
        }
    },
    {
        path: '/register',
        component: Register,
        meta: {
            show: false
        }
    },
    {
        path: '/privacy',
        component: Privacy,
        meta: {
            show: false
        }
    },
    {
        path: '/events/:event',
        component: EventPage,
        meta: {
            show: false
        }
    },
    //重定向
    {
        path: '*',
        redirect: '/home'
    }
]
