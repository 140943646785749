<template>
  <div class="register-container">
    <RegHeader></RegHeader>
    <div class="reg-main">
      <RegStep :activeStep="activeStep"></RegStep>
        <RegMainChoose v-if="regType==0" @chooseRegType="chooseRegType"></RegMainChoose>
        <RegMainDept v-if="regType==1"></RegMainDept>
        <RegMainPerson v-if="regType==2"></RegMainPerson>
    </div>
  </div>
</template>

<script>
  import RegStep from '@/components/RegisterStep'
  import RegHeader from '@/components/RegisterHeader'
  import RegMainChoose from '@/components/RegMain/RegMainChoose'
  import RegMainDept from '@/components/RegMain/RegMainDept'
  import RegMainPerson from '@/components/RegMain/RegMainPerson'
  export default {
    name: 'Register',
    components: {RegHeader, RegStep, RegMainChoose, RegMainDept, RegMainPerson},
    data() {
      return {
        activeStep: 1,
        regType: 0
      }
    },
    methods: {
      chooseRegType(regType) {
        this.activeStep = 2
        this.regType = regType
      }
    }
  }
</script>

<style lang="scss" scoped>
  .register-container {
    box-sizing: border-box;
    width: 83.3%;
    max-width: 1600px;
    min-height: 600px;
    background-color: #FFF;
    border-radius: 30px;
    margin: 50px auto 50px auto;
    padding: 0 38px;
    .reg-main {
      width: 55%;
      margin: 0 auto;

    }
  }

  @media screen and (min-width: 1023px) and (max-width: 1600px) {
    .register-container {
      .reg-main {
        width: 76%;
      }
    }
  }
</style>
