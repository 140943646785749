<template>
  <!-- 底部 -->
  <div class="footer">

  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 50px;
  background-color: #1476F4;
  font-size: 16px;
  color: #FFF;
}
</style>
