<script>
export default {
  name:'PrivacyPage'
}
</script>

<template>
  <div class="outer-box">
    <h2 class="h2-privacy-title">用户协议、隐私保护指引</h2>
    <div class="privacy-content">
      <h3>统一身份认证平台用户协议</h3>
      <h4>一、协议概述</h4>

      <p>本协议旨在明确您（以下称“用户”）在使用本统一身份认证平台（以下简称“平台”）服务过程中，与平台提供方之间的权利与义务关系。请您在注册或使用平台服务前，认真阅读本协议内容，并确认您已充分理解并同意接受本协议的全部条款。</p>

      <h4>二、服务内容</h4>

      <p>平台提供统一的身份认证服务，包括但不限于账号注册、登录、身份验证等。</p>
      <p>用户可以通过平台实现单点登录，方便访问接入平台的各类应用服务。</p>
      <h4>三、用户权利和义务</h4>

      <p>用户有权根据本协议使用平台提供的服务。</p>
      <p>用户应遵守国家法律法规，不得利用平台从事违法、违规活动。</p>
      <p>用户应保证注册信息的真实性、准确性，并承担因信息不真实、不准确带来的后果。</p>
      <p>用户应妥善保管自己的账号和密码，不得将账号转让、出租或借予他人使用。</p>
      <h4>四、平台权利和义务</h4>

      <p>平台有权根据本协议提供服务，并有权对服务进行升级、改进或调整。</p>
      <p>平台有权对用户的注册信息进行审核，对不符合要求的注册信息有权拒绝或注销。</p>
      <p>平台应保障用户信息的安全，采取合理的技术和管理措施防止信息泄露、损毁或丢失。</p>
      <h4>五、免责条款</h4>

      <p>因不可抗力或平台无法控制的原因导致的服务中断或故障，平台不承担责任。</p>
      <p>用户因违反本协议或国家法律法规而导致的损失，平台不承担责任。</p>
      <h4>六、协议变更与终止</h4>

      <p>平台有权根据需要对本协议进行修订，修订后的协议将通过平台公告或其他合理方式通知用户。</p>
      <p>用户如不同意修订后的协议，有权停止使用平台服务；继续使用的，视为接受修订后的协议。</p>
      <p>在用户违反本协议或国家法律法规的情况下，平台有权终止本协议并停止提供服务。</p>
      <h4>七、争议解决</h4>

      <p>本协议的解释、履行和争议解决均适用中华人民共和国法律。因本协议引起的或与本协议有关的任何争议，双方应首先友好协商解决；协商不成的，任何一方均有权向平台提供方所在地人民法院提起诉讼。</p>

      <h3>统一身份认证平台隐私保护指引</h3>

      <h4>一、隐私保护原则</h4>

      <p>我们尊重并保护用户的隐私权，将遵循合法、正当、必要的原则收集、使用、存储和保护用户的个人信息。</p>

      <h4>二、信息收集与使用</h4>

      <p>我们将收集用户注册时提供的基本信息，如姓名、手机号码等，以便为用户提供身份认证服务。</p>
      <p>我们可能会根据服务需要，收集用户的设备信息、使用习惯等，以便优化服务体验。</p>
      <p>我们将严格限制信息的使用范围，仅在提供服务和维护平台安全的情况下使用用户的个人信息。</p>
      <h4>三、信息共享与披露</h4>

      <p>我们不会无故共享或披露用户的个人信息，除非得到用户的明确同意或法律允许。</p>
      <p>在法律允许的范围内，我们可能会将用户信息提供给合作伙伴，以便为用户提供更优质的服务，但我们会与合作伙伴签订严格的保密协议，确保用户信息安全。</p>
      <h4>四、信息安全保障</h4>

      <p>我们将采取合理的技术和管理措施，保障用户信息的安全，防止信息泄露、损毁或丢失。</p>
      <p>若发生信息安全事件，我们将及时采取补救措施，并按照规定向有关部门报告。</p>
      <h4>五、用户权利</h4>

      <p>用户有权查询、更正自己的个人信息。</p>
      <p>用户有权要求我们删除其个人信息，但法律另有规定的除外。</p>
      <p>用户有权投诉我们违反隐私保护规定的行为，我们将及时处理用户的投诉。</p>
      <h4>六、其他</h4>

      <p>本隐私保护指引的解释、履行和争议解决均适用中华人民共和国法律。</p>
      <p>若用户对隐私保护指引有任何疑问或建议，可通过平台提供的联系方式与我们联系。</p>
      <p>请用户认真阅读并理解本协议和隐私保护指引的内容，如有疑问或需要帮助，请及时与我们联系。我们致力于为用户提供安全、便捷的服务，感谢您的信任与支持！</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.outer-box{
  width: 100%;

  .h2-privacy-title{
    text-align: center;
    padding-top: 20px;
  }

  .privacy-content{
    max-width: 800px;
    margin: 0 auto;
    padding: 50px 0;

    h3{
      margin: 20px 0 0 0;
    }
    h4{
      margin: 15px 0;
    }
    p{
      line-height: 24px;
    }
  }
}

</style>
