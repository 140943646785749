<template>
  <div class="header">
    <div class="header-left">
      <i class="iconfont icon-zhuce"></i>
      <i class="iconfont icon-cs-xy-1"></i>
    </div>
    <div class="header-right">
      <span>已有账号？去</span>
      <router-link :to="`/login/${client_id}`">登录</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegHeader",
  data() {
    return {
      client_id: localStorage.getItem('client_id') || process.env.VUE_APP_CLIENT_ID
    }
  },
  methods: {},
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-left {
    display: flex;
    align-items: center;
    line-height: 60px;

    i {
      color: $base;

      &:first-child {
        font-size: 36px;
      }

      &:last-child {
        font-size: 40px;
        margin-top: 6px;
      }
    }
  }

  .header-right {
    font-size: 18px;

    span {
      color: #7E7E7E;
    }

    a {
      font-weight: bold;
      color: $base;
      text-decoration: underline;
    }
  }
}
</style>
