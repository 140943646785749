import axios from "axios";
//引入nprogress及其css样式，进度条颜色是可以改的
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';

const requests = axios.create({
    baseURL: '/mock',
    timeout: 5000
});

requests.interceptors.request.use((config) => {
    //进度条开始
    nprogress.start();
    return config;
}, (error) => {
    return Promise.reject(new Error('fail'));
});

requests.interceptors.response.use((res) => {
    //进度条结束
    nprogress.done();
    return res.data;
}, (error) => {
    return Promise.reject(new Error('fail'));
});

export default requests;