<template>
  <div class="step-container">
    <div class="title one" :class="{active: activeStep >= 1}">注册类型</div>
    <div class="title two" :class="{active: activeStep == 2}">信息登记</div>
    <div class="circle " :class="{active: activeStep => 1}">
      <div>1</div>
    </div>
    <div class="step-line" :class="{active: activeStep == 2}"></div>
    <div class="circle" :class="{active: activeStep == 2}">
      <div>2</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegStep",
  props: {
    activeStep: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
  .step-container {
    position: relative;
    width: 100%;
    margin: 60px auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      position: absolute;
      bottom: 40px;
      width: 80px;
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      color: #B6B6B6;
      &.active {
        font-weight: bold;
        color: $base;
      }
      &.one {
        left: -25px;
      }
      &.two {
        right: -25px;
      }
    }
    .circle {
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      border-color: #CCC;
      border-width: 1px;
      border-style: solid;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      div {
        width: 24px;
        height: 24px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: #CCC;
      }
      &.active {
        border-color: $base;
        div {
          background-color: $base;
        }
      }
    }
    .step-line {
      flex: 0.99;
      height: 6px;
      background-color: #CCC;
      &.active {
        background-color: $base;
      }
    }
  }
</style>
