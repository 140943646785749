//API进行统一管理
import requests from "@/api/request";
import mockRequests from '@/api/ajaxMock';

//axios请求后返回的是Promise

//法人注册
export const corporateRegistration = (params) => requests({url: '/auth/legal/register', method: 'post', data: params});

//自然人注册
export const personRegistration = (params) => requests({url: '/auth/natural/register', method: 'post', data: params});

//注册发送短信
export const sendSms = (params) => requests({url: '/auth/send/register/sms', method: 'post', data: params});

//获取上传文件临时授权凭证
export const authorizationCertificate = (params = {}) => requests({
    url: '/auth/upload/certificate/register',
    method: 'post',
    data: params
});

export const getCodeImg = () => requests({
    url: '/authsys/captchaImage',
    headers: {isToken: false},
    method: 'get',
    timeout: 20000
});

//登录
export const reqUserLogin = (params, client_id) => {
    let url = '/auth/login';
    if (['password', 'sms_code'].indexOf(params.grantType) >= 0 && !!params.verify && !!params.uuid) {
        url += `?verify=${params.verify}&uuid=${params.uuid}`;
    }
    return requests({url, method: 'post', data: params, client_id});
};

//退出登录
export const reqOutLogin = () => requests({url: '/authsys/api/tec/user/logout', method: 'post'});

//登录发送短信
export const loginSendSms = (params) => requests({url: '/auth/openapi/send/sms', method: 'post', data: params});

//创建图片key
export const getQrcodeKey = () => requests({url: `/auth/wchart/key`, method: 'post'});

//微信登录绑定手机验证码
export const sendWxSms = (params) => requests({url: `/auth/wchart/send/bind/code`, method: 'post', data: params});

//微信登录绑定手机
export const wxBinding = (params) => requests({url: `/auth/wchart/bind/phone`, method: 'post', data: params});

//微信登录
export const wxLogin = (key) => requests({url: `/auth/wchart/code/${key}`, method: 'get'});

//获取所有的应用
export const getAppList = () => requests({url: '/authsys/api/app/create/getAppList', method: 'post'});

//用户申请应用使用权限
export const saveUserUseApp = (params) => requests({
    url: `/authsys/api/user/app/saveUserUseApp`,
    method: 'post',
    data: params
});

//获取用户的应用列表
export const getAppListByUser = (params) => requests({
    url: '/authsys/api/user/app/getAppListByUser',
    method: 'post',
    data: params
});

//获取用户的应用详情
export const getUserUseAppDetails = (id) => requests({
    url: `/authsys/api/user/app/getUserUseAppDetails?id=${id}`,
    method: 'get'
});

//重置密码发送短信
export const resetPasswdSms = (params) => requests({
    url: '/auth/api/send/reset/passwd/sms',
    method: 'post',
    data: params
});

//重置密码
export const resetPasswd = (params) => requests({url: '/auth/api/reset/passwd', method: 'post', data: params});
