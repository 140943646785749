<template>
  <div class="header-container">
    <div class="header-left">
      <img src="@/assets/images/slogan.png">
      <div class="menu-box" v-if="user">
        <div v-for="(item,index) in menu" class="menu-dv" @click="navTo(item.path)"
             :class="$route.path === item.path ? 'menu-cor' : ''">
          <i :class="item.icon"></i>
          <div class="ml5">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <el-dropdown v-if="user" @command="handleCommand">
      <div class="header-right cf f20" @click="navTo('/personal')">
        <img src="@/assets/tx.png" alt="" class="image">
        <span>{{ user.name }}</span>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item style="min-width: 120px; text-align: center;" command="resetPsd">修改密码</el-dropdown-item>
        <el-dropdown-item style="min-width: 120px; text-align: center;" command="outLgin">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="header-right" v-else>
      <router-link to="/register">
        <img src="@/assets/images/avatar-default.png">
        <span>立即注册</span>
      </router-link>
    </div>
    <el-dialog :visible.sync="show" title="修改密码" width="600px">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="手机号">
          <el-input placeholder="请输入" v-model="phone" type="text" disabled>
            <template slot="append">
              <div @click="getCode" style="cursor: pointer;">
                <span v-if="codeStatus">获取验证码</span>
                <span v-else>重新发送({{ codeCount }}s)</span>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input placeholder="请输入" v-model="form.code" type="number"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="pwd">
          <el-input placeholder="请输入" v-model="form.pwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="pwd1">
          <el-input placeholder="请输入" v-model="form.pwd1" type="password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import md5 from 'js-md5';

export default {
  name: "TopNav",
  data() {
    return {
      menu: [{
        title: '首页',
        path: '/home',
        icon: 'el-icon-s-home'
      }, {
        title: '应用申请列表',
        path: '/list',
        icon: 'el-icon-s-order'
      }],
      client_id: localStorage.getItem('client_id') || process.env.VUE_APP_CLIENT_ID,
      show: false,
      form: {
        phone: '',
        code: '',
        pwd: '',
        pwd1: ''
      },
      rules: {
        code: [{
          required: true,
          message: '请输入',
          trigger: 'change'
        }],
        pwd: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入密码'));
              } else {
                if (this.form.pwd1 !== '') {
                  this.$refs.ruleForm.validateField('pwd1');
                }
                callback();
              }
            }
          }
        ],
        pwd1: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请再次输入密码'));
              } else if (value !== this.form.pwd) {
                callback(new Error('两次输入密码不一致!'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
      },
      codeStatus: true,
      codeCount: 60
    }
  },
  computed: {
    user() {
      return this.$store.state.user.userInfo;
    },
    phone(){
      const phone = this.$store.state.user?.userInfo?.phone || '';
      return phone.substring(0, 3) + '****' + phone.substring(8);
    }
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$API.resetPasswd({
            ...this.form,
            pwd: md5(this.form.pwd)
          }).then(res => {
            this.$message.success('密码修改成功')
            this.show = false;
          })
        } else {
          return false;
        }
      });
    },
    getCode() {
      this.codeStatus = false;
      let codeClock = setInterval(() => {
        this.codeCount = this.codeCount - 1
        if (this.codeCount === -1) {
          this.codeStatus = true
          this.codeCount = 60
          clearInterval(codeClock)
        }
      }, 1000)
      this.$API.resetPasswdSms({}).then(res => {
        if (res.code === 200) {
          this.$message.success('发送验证码成功');
        } else {
          this.$message.error('发送验证码失败');
        }
      }).catch(() => {
        this.$message.error('发送验证码失败');
      });
    },
    handleCommand(e) {
      console.log(e)
      if (e === 'outLgin') {
        this.$API.reqOutLogin().then(res => {
          localStorage.removeItem('user')
          localStorage.removeItem('TOKEN')
          this.$message.success('退出成功')
          this.$router.push(`/login/${this.client_id}`)
        })
      }
      if (e === 'resetPsd') {
        this.show = true
      }
    },
    navTo(path) {
      if (this.$route.path === path) return
      this.$router.push(path)
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.header-container {
  box-sizing: border-box;
  width: 100%;
  height: 76px;
  padding: 0 30px;
  background: url("../../assets/images/bg-top.png") center center no-repeat;
  background-size: cover;
  //box-shadow: 0 1px 0 1px rgba(20,118,244,1);
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-left {
    display: flex;
    align-items: center;

    img {
      height: 36px;
      margin-top: 10px;
    }

    .menu-box {
      display: flex;
      align-items: center;

      .menu-dv {
        margin-left: 30px;
        display: flex;
        align-items: center;
        color: #FFF;
        font-size: 20px;
        cursor: pointer;
        height: 76px;
        padding: 0 20px;
      }

      .menu-cor {
        color: #409EFF;
        background: #fff;

      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      color: #FFF;

      &:hover {
        color: #FFF !important;
        text-decoration: none;
      }

      img {
        width: 50px;
        height: 50px;
        margin-right: 8px;
      }

      span {
        font-size: 16px;
      }
    }
  }
}

/*@media screen and (min-width: 1023px) and (max-width: 1600px) {
  .header-container {
    height: 56px;

    .header-left {
      img {
        height: 30px;
        margin-top: 8px;
      }
    }

    .header-right {
      a {
        img {
          width: 40px;
          height: 40px;
          margin-right: 4px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }
}*/
</style>
