import axios from "axios";
import store from '@/store';
import {MessageBox} from "element-ui";
//引入nprogress及其css样式，进度条颜色是可以改的
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';

const requests = axios.create({
    baseURL: '',
    timeout: 5000
});
requests.interceptors.request.use((config) => {
    //进度条开始
    nprogress.start();
    //需要携带token带给服务器
    if (store.state.user.token) {
        config.headers.token = store.state.user.token;
    }
    config.headers.client_id = config.client_id || process.env.VUE_APP_CLIENT_ID
    return config;
}, (error) => {
    return Promise.reject(new Error('fail'));
});

requests.interceptors.response.use(async (res) => {
    //进度条结束
    nprogress.done();
    if (res.data.code !== 200) {
        if (res.data.code === 4041) {
            localStorage.removeItem('user');
            localStorage.removeItem('TOKEN');
            await MessageBox.alert('登录过期，请重新登录', {type: 'warning'});
            window.location.replace(`${location.origin}/login/${process.env.VUE_APP_CLIENT_ID}`);
        }
    }
    return res.data;
}, (error) => {
    return Promise.reject(new Error('请求失败'));
});

export default requests;
