<script>
export default {
  name: 'EventConsumer',
  data() {
    return {
      msg: '',
      client_id: localStorage.getItem('client_id') || process.env.VUE_APP_CLIENT_ID
    }
  },
  mounted() {
    const event = this.$route.params.event;
    if (event === 'logout') {
      this.$API.reqOutLogin().then(() => {
        localStorage.removeItem('user')
        localStorage.removeItem('TOKEN')
        this.$message.success('退出成功')
        this.$router.push(`/login/${this.client_id}`)
      });
      return;
    }
    this.msg = '指令错误...';
  },
  methods: {}
}
</script>

<template>
  <div>{{msg}}</div>
</template>

<style scoped lang="scss">

</style>
