<template>
	<div class="reg-main-container">
		<h2 class="reg-notice">完成<span>自然人</span>主体信息认证</h2>
		<div class="reg-main-box">
			<h2 class="reg-desc">统一用户认证平台致力于打造真实、合法、有效的互联网服务平台，为了更好的保障用户的合法权益，请认真填写以下登记信息。</h2>

			<div class="reg-section">
				<div class="reg-title">
					个人基本资料
				</div>
				<div class="reg-content">
					<div class="reg-content-row">
						<label class="reg-name">身份证姓名：</label>
						<input maxlength="30" type="text" class="cus-input" v-model="formData.name" placeholder="请输入">
					</div>
					<div class="reg-content-row">
						<label class="reg-name">身份证号码：</label>
						<input  maxlength="18" type="text" name="idCard" class="cus-input" v-model="formData.idCard" placeholder="请输入">
					</div>
					<div class="reg-content-row flex-start">
						<label class="reg-name">身份证正反面照片：</label>
						<ImageUpload v-model="formData.picts" :limit="2"></ImageUpload>
					</div>
				</div>
			</div>

			<div class="reg-section">
				<div class="reg-title">
					账号信息
				</div>

				<div class="reg-content lg-mg">
					<!-- <div class="reg-content-row">
						<label class="reg-name">账号：</label>
						<input type="text" name="username" class="cus-input" v-model="formData.username"
							placeholder="请输入">
					</div> -->
					<div class="reg-content-row">
						<label class="reg-name">绑定手机号：</label>
						<input maxlength="11" type="number" name="phone" class="cus-input" v-model="formData.phone" placeholder="请输入">
					</div>
					<div class="reg-content-row">
						<label class="reg-name">验证码：</label>
						<input maxlength="6" type="number" name="code" class="cus-input" autocomplete="off" v-model="formData.code"
							placeholder="请输入">
						<el-button class="btn-code" :disabled="!codeStatus" type="primary" @click="getCode">
							<span v-if="codeStatus">获取验证码</span>
							<span v-else>重新发送({{codeCount}}s)</span>
						</el-button>
					</div>
					<div class="reg-content-row">
						<label class="reg-name">登录密码：</label>
						<input maxlength="32" type="password" name="secret" class="cus-input" autocomplete="off" v-model="formData.secret"
							placeholder="请输入">
					</div>
					<div class="reg-content-row">
						<label class="reg-name">确认密码：</label>
						<input maxlength="32" type="password" name="password" class="cus-input" v-model="formData.password"
							placeholder="请输入">
					</div>
					<div class="reg-content-row">
						<label class="reg-name important">注意事项：</label>
						<h2 class="tips">完成以上操作后进入审核阶段，预计审核时间为3个工作日。</h2>
					</div>
				</div>
			</div>
			<div class="reg-row">
				<el-button class="" type="primary" @click="submit">提交审核</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import md5 from 'js-md5'
	export default {
		name: "RegMainPerson",
		data() {
			return {
				codeStatus: true,
				codeCount: 60,
				formData: {
					personType: 'NATURAL',
					picts: [],
					secret: '',
					password: '',
          phone: '',
          code: '',
          idCard: '',
          name: ''
				}
			}
		},
		computed: {

		},
		methods: {
			submit() {
				if (this.formData.secret !== this.formData.password){
          return this.$message.warning('两次密码输入不一致');
        }
				this.$API.personRegistration({
					...this.formData,
					secret: md5(this.formData.secret),
					picts: this.formData.picts.map(item => item.url)
				}).then(res => {
					if (res.code === 200) {
						this.$message.success('注册成功，正在登录');
            //this.$router.replace(`/login/${process.env["VUE_APP_CLIENT_ID"]}`)
						this.userLogin({
							grantType: 'password',
							username: this.formData.phone,
							password: this.formData.secret,
              registerKey: res.data.params.registerKey
						})
					} else {
						this.$message.warning(res.msg)
					}
				}).catch(err => {});
			},
			//登录
			async userLogin(form) {
				try {
          const {state, message, type} = await this.$store.dispatch('userLogin', form);
          if (state === 'ok') {
            this.$message[type](message);
            await this.$router.replace('/home');
            return;
          }
          this.$message[type](message);
				} catch (err) {
          this.$message.warning(err.message)
				}
			},
			async getCode() {
				let regs =
					/^((\+|00)86)?1((3[\d])|(4[5,6,7,9])|(5[0-3,5-9])|(6[5-7])|(7[0-8])|(8[\d])|(9[1,8,9]))\d{8}$/
				if (!regs.test(this.formData.phone)) return this.$message.warning('请输入正确的手机号码')
				this.codeStatus = false
				let codeClock = setInterval(() => {
					this.codeCount = this.codeCount - 1
					if (this.codeCount === -1) {
						this.codeStatus = true
						this.codeCount = 60
						clearInterval(codeClock)
					}
				}, 1000)
				this.$API.sendSms({
					phone: this.formData.phone,
					clientId: process.env.VUE_APP_CLIENT_ID
				}).then(res => {

				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.reg-main-container {
		width: 100%;
		margin-top: 40px;
		margin-bottom: 40px;

		.reg-notice {
			width: 100%;
			font-size: 20px;
			color: #999;

			span {
				color: #27BA77;
			}
		}

		.reg-main-box {
			display: flex;
			flex-direction: column;
			box-shadow: 0px 2px 30px 0px rgba(207, 207, 207, 0.24);
			border-radius: 20px;
			padding: 20px;
			margin-top: 20px;

			.reg-desc {
				font-size: 16px;
				color: #85B4C7;
			}

			.reg-section {
				margin-top: 40px;

				.reg-title {
					box-sizing: border-box;
					position: relative;
					height: 30px;
					padding-left: 14px;
					line-height: 30px;
					font-size: 18px;
					color: #272C30;

					&::before {
						content: '';
						position: absolute;
						left: 0;
						top: 7px;
						width: 6px;
						height: 16px;
						border-radius: 10px;
						background-color: $base;
					}
				}

				.reg-content {
					box-sizing: border-box;
					width: 100%;
					padding: 0 80px;

					&.lg-mg {
						.reg-content-row {
							margin-bottom: 40px;
						}
					}

					.reg-content-row {
						width: 100%;
						margin-top: 40px;
						display: flex;
						align-items: center;

						&.flex-start {
							align-items: flex-start;
						}

						&.pd-left-200 {
							padding-left: 200px;
						}

						.upload-company-pict.hide {
							::v-deep .el-upload {
								display: none !important;
							}
						}

						.reg-name {
							box-sizing: border-box;
							min-width: 200px;
							padding: 0 20px;
							text-align: right;
							font-size: 16px;
							color: #78818A;

							&.important {
								font-weight: bold;
								color: #3F5978;
							}
						}

						.btn-code {
							//height: 46px;
							margin-left: 12px;
						}

						.tips {
							color: #9CA7AE;
						}
					}
				}
			}

			.reg-row {
				box-sizing: border-box;
				width: 100%;
				text-align: center;
			}
		}
	}

	@media screen and (min-width: 1023px) and (max-width: 1600px) {}
</style>
