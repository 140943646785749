<template>
  <div id="app">
    <TopNav v-if="$route.path !== '/wxLogin'"/>
    <div class="main">
      <router-view></router-view>
    </div>
<!--    <Footer></Footer>-->
  </div>
</template>

<script>
import TopNav from '@/components/TopNav'
import Footer from '@/components/Footer'
export default {
  name: 'App',
  data() {
    return {

    }
  },
  components: {TopNav, Footer},
  mounted() {
    //通知Vuex发送请求，获取数据，存储于仓库当中

  }
}
</script>

<style lang="scss">
	@import url("assets/styles/style.css");
  #nprogress .bar {
    background: #ED2B25 !important;
  }
  ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button{
    -webkit-appearance: none !important;
    margin: 0;
  }
  input[type="number"]{-moz-appearance:textfield;}
  #app {
    width: 100%;
    font-family: "PingFang SC","Microsoft YaHei",SansSerif;
    .main {
      width: 100%;
      min-height: calc(100vh - 76px);
      background: url("./assets/images/bg.png") center top repeat-y;
      background-size: cover;
      display: flex;
    }
  }
  .btn-primary {
    width: 100%;
    height: 60px;
    border-radius: 36px;
    background-color: $base;
    color: #FFF;
    font-size: 22px;
    cursor: pointer;
    outline: none;
    border: none;
  }
  .cus-input {
    box-sizing: border-box;
    flex:1;
    height: 46px;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 10px;
    padding: 0 20px;
    font-size: 16px;
    color: #666;
    overflow: hidden;
    &::placeholder {
      color: #BABABA;
    }
  }

  @media screen and (min-width: 1023px) and (max-width: 1600px) {
    #app {
      .main {
        min-height: calc(100vh - 56px);
      }
    }
    .btn-primary {
      height: 46px;
      font-size: 18px;
    }
    .cus-input {
      height: 46px;
      padding: 0 12px;
      font-size: 14px;
    }
  }

  /*transition效果*/
  /* 进入动画 */
  .form-enter-active, .form-leave-active {
    transition: opacity .1s;
  }
  /* 离开动画 */
  .form-enter, .form-leave-to {
    opacity: 0;
  }
</style>
