<template>
  <div class="login-container">
    <div class="top">
      <div class="banner">
        <img src="@/assets/images/bg-login-banner.png" alt="">
      </div>
      <div class="login-section">
        <div class="login-navbar">
          <div class="navbar-item" :class="{active: form.grantType === 'password'}"
               @click="changeLoginType('password')">
            账号密码登录
          </div>
          <div class="navbar-item" :class="{active: form.grantType === 'sms_code'}"
               @click="changeLoginType('sms_code')">
            手机号登录
          </div>
          <div class="navbar-item" :class="{active: form.grantType === 'wx'}" @click="changeLoginType('wx')">
            微信登录
          </div>
        </div>
        <transition name="form">
          <div class="login-form" v-if="form.grantType === 'password'">
            <div class="login-form-item">
              <img class="icon" src="@/assets/icon/i-user.png"/>
              <input maxlength="11" class="login-input" name="username" type="number" placeholder="请输入账号"
                     v-model="form.username">
            </div>
            <div class="login-form-item">
              <img class="icon" src="@/assets/icon/i-pwd.png"/>
              <input class="login-input" name="password" type="password" maxlength="32" placeholder="请输入密码"
                     v-model="form.password">
            </div>
            <div class="login-form-item">
              <img class="icon" src="@/assets/icon/i-code.png"/>
              <input class="login-input" name="captcha" maxlength="5" type="number" placeholder="图形验证码"
                     autocomplete="off"
                     v-model="form.verify"/>
              <img v-if="!!verifyCode" class="btn-code captcha" :src="verifyCode" @click.prevent="getVerifyCode"/>
              <div v-else class="btn-code captcha"  @click.prevent="getVerifyCode">
                <i class="el-icon-picture-outline"></i>
              </div>
            </div>
            <!-- <div class="login-form-checkbox">
              <el-checkbox class="remember" v-model="remember">记住登录状态</el-checkbox>
              <div class="forget-pwd cursor-pointer" @click="show = true">忘记密码？</div>
            </div> -->
            <button class="btn-primary" @click="userLogin">登录</button>
          </div>
          <div class="login-form" v-if="form.grantType === 'sms_code'">
            <div class="login-form-item">
              <img class="icon" src="@/assets/icon/i-phone.png"/>
              <input class="login-input" name="phone" type="number" maxlength="11" placeholder="请输入手机号"
                     v-model="form.phone">
            </div>
            <div class="login-form-item">
              <img class="icon" src="@/assets/icon/i-code.png"/>
              <input class="login-input" name="code" type="number" maxlength="6" placeholder="请输入验证码"
                     autocomplete="off"
                     v-model="form.code">
              <el-button class="btn-code" :disabled="!codeStatus" type="primary" round @click="getCode">
                <span v-if="codeStatus">获取验证码</span>
                <span v-else>重新发送({{ codeCount }}s)</span>
              </el-button>
            </div>
            <div class="login-form-item">
              <img class="icon" src="@/assets/icon/i-code.png"/>
              <input class="login-input" name="captcha" maxlength="5" type="number" placeholder="图形验证码"
                     autocomplete="off"
                     v-model="form.verify"/>
              <img v-if="!!verifyCode" class="btn-code captcha" :src="verifyCode" @click.prevent="getVerifyCode"/>
              <div v-else  class="btn-code captcha"  @click.prevent="getVerifyCode">
                <i class="el-icon-picture-outline"></i>
              </div>
            </div>
            <button class="btn-primary" @click="userLogin">登录</button>
          </div>
          <div class="login-form" v-if="form.grantType === 'wx'">
            <div class="wechat-box">
              <div class="qrcode-box">
                <div ref="qrCodeUr1" class="qrCode" @click="getQrcode"></div>
                <div class="qrCode-tips" @click="getQrcode" v-if="num >= 300">
                  <i class="el-icon-warning-outline"></i>
                  <div>二维码失效点击刷新</div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <div class="agreement" v-show="form.grantType !== 'wx'">
          <el-checkbox class="agree" v-model="agree">
            已阅读并同意<router-link to="/privacy">用户协议、隐私保护指引</router-link>
          </el-checkbox>
        </div>
      </div>
    </div>

    <div class="middle">
      <div class="intro-item">
        <img src="@/assets/images/union-user.png">
        <h2>统一用户</h2>
      </div>
      <div class="intro-item">
        <img src="@/assets/images/union-rz.png">
        <h2>统一认证</h2>
      </div>
      <div class="intro-item">
        <img src="@/assets/images/union-auth.png">
        <h2>统一授权</h2>
      </div>
      <div class="intro-item">
        <img src="@/assets/images/union-check.png">
        <h2>统一审计</h2>
      </div>
    </div>

    <div class="bottom">
      <button class="btn-to-register" @click="$router.push('/register')">立即注册认证</button>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'

export default {
  name: 'Login',
  data() {
    return {
      verifyCode: '',
      remember: false,
      codeStatus: true,
      codeCount: 60,
      agree: false,
      form: {
        grantType: 'password',
        username: '',
        password: '',
        phone: '',
        code: '',
        verify: '',
        uuid: ''
      },
      timer: null,
      key: '',
      num: 0
    }
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  mounted() {
    let loginInfo = localStorage.getItem('loginInfo')
    if (loginInfo) {
      this.form.username = loginInfo.username
      this.form.password = loginInfo.password
    }
    const clientId = this.$route.params.clientId;
    localStorage.setItem('client_id', clientId);
    if (localStorage.getItem('TOKEN')) {
      if (process.env["VUE_APP_CLIENT_ID"] !== clientId) {
        this.$API.reqUserLogin({
          grantType: 'business_token',
          token: localStorage.getItem('TOKEN')
        }, clientId).then(res => {
          if (res.code === 200) {
            location.replace(`${res.data.redirect_uri}?code=${res.data.code}`);
          } else {
            this.$message.error('跳转失败:' + res.msg);
            this.$router.replace('/home');
          }
        });
      } else {
        this.$router.replace('/home');
      }
    }else{
      this.getVerifyCode();
    }
  },
  methods: {
    getVerifyCode() {
      this.$API.getCodeImg().then(({uuid, img}) => {
        this.verifyCode = "data:image/gif;base64," + img;
        this.form.uuid = uuid;
      });
    },
    creatQrCode() {
      new QRCode(this.$refs.qrCodeUr1, {
        text: `${location.origin}/wxLogin?key=${this.key}`, // 需要转换为二维码
        width: 296,
        height: 296,
        colorDark: '#000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    async getQrcode() {
      if (this.$refs.qrCodeUr1) this.$refs.qrCodeUr1.innerHTML = ''
      this.num = 0
      const res = await this.$API.getQrcodeKey();
      this.key = res.data;
      this.creatQrCode();
    },
    startLoopWxLogin() {
      this.num++
      this.$API.wxLogin(this.key).then(async res => {
        if (res.code === 200 && res.data) {
          const res1 = await this.$store.dispatch('userLogin', {
            grantType: 'wx',
            ...res
          });
          clearTimeout(this.timer);
          if (res1) {
            this.$router.replace('/home')
          }
        } else {
          if (this.num >= 300) return this.$message.warning('二维码失效，点击刷新');
          this.timer = setTimeout(() => this.startLoopWxLogin(), 2000);
        }
      });
    },
    async changeLoginType(type) {
      this.form.grantType = type
      if (type === 'wx') {
        await this.getQrcode()
        this.startLoopWxLogin();
      } else {
        clearTimeout(this.timer)
      }
    },
    async getCode() {
      let regs = /^1[3-9][0-9]{9}$/;
      if (!regs.test(this.form.phone)) return this.$message.warning('请输入正确的手机号码')
      if(!this.codeStatus){
        return;
      }
      this.codeStatus = false
      let codeClock = setInterval(() => {
        this.codeCount = this.codeCount - 1
        if (this.codeCount === -1) {
          this.codeStatus = true
          this.codeCount = 60
          clearInterval(codeClock)
        }
      }, 1000);
      this.$API.loginSendSms({
        phone: this.form.phone,
        clientId: this.$route.params.clientId
      }).then(res => {

      })
    },
    //登录
    async userLogin() {
      if (!this.agree) {
        this.$message.warning('请阅读并同意用户协议');
        return;
      }
      if(!this.form.verify){
        this.$message.warning('请输入图形验证码');
        return;
      }
      if (this.remember) {
        localStorage.setItem('loginInfo', JSON.stringify(this.form))
      }
      try {
        const {state, message, type} = await this.$store.dispatch('userLogin', {...this.form});
        if (state === 'ok') {
          this.$message[type](message);
          await this.$router.replace('/home');
          return;
        }
        this.getVerifyCode();
        this.$message[type](message);
      } catch (err) {
        this.getVerifyCode();
        // this.$message.error('error:登录失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  overflow: hidden;
  box-sizing: border-box;
  //width: 83.3%;
  max-width: 1600px;
  min-width: 1100px;
  margin: 30px 100px;
  background-color: #FFF;
  border-radius: 30px;
  box-shadow: 0 0 17px 6px rgba(8, 131, 246, 0.24);
  display: flex;
  flex-direction: column;

  .top {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .banner {
      width: 50%;
      border-radius: 0 30px 30px 30px;
      background-image: linear-gradient(to bottom, #015EEA, #F7F7F7);

      img {
        width: 100%;
        height: auto;
      }
    }

    /*登录部分*/
    .login-section {
      box-sizing: border-box;
      flex: 1;
      height: 100%;
      padding: 0 90px;

      .login-navbar {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .navbar-item {
          font-size: 22px;
          color: #7E7E7E;
          position: relative;
          transition: all linear 100ms;
          cursor: pointer;

          &.active {
            //font-size: 28px;
            color: $text-color-active;

            &:after {
              content: "";
              position: absolute;
              bottom: -22px;
              left: calc(50% - 15px);
              width: 30px;
              height: 8px;
              border-radius: 10px;
              background-color: $text-color-active;
            }
          }
        }
      }

      .login-form {
        width: 100%;
        margin-top: 52px;

        &.active {
          display: flex;
        }

        .login-form-item {
          position: relative;
          width: 100%;
          height: 70px;
          margin-bottom: 26px;

          .icon {
            position: absolute;
            left: 26px;
            top: calc(50% - 15px);
            width: 30px;
            height: 30px;
          }

          .login-input {
            box-sizing: border-box;
            width: 100%;
            height: 70px;
            outline: none;
            border: none;
            background-color: #F7F7F7;
            border-radius: 30px;
            padding: 0 90px;
            font-size: 20px;
            color: #333;
            overflow: hidden;

            &::placeholder {
              color: #BABABA;
            }
          }

          .captcha {
            background: #bbbbbb;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 40px;
            user-select: none;
            $cut-width: 2px;
            clip-path: polygon(
                    $cut-width $cut-width,
                    calc(100% - $cut-width) $cut-width,
                    calc(100% - $cut-width) calc(100% - $cut-width),
                    $cut-width calc(100% - $cut-width)
            );
            border-radius: 0 !important;
          }

          .btn-code {
            position: absolute;
            right: 10px;
            top: 10px;
            height: 50px;
            border-radius: 24px;
          }
        }

        .login-form-checkbox {
          box-sizing: border-box;
          margin-bottom: 28px;
          padding-left: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;

          .remember {
            transform: scale(1.2);
          }

          .forget-pwd {
            color: #409EFF;
            font-size: 16px;
          }
        }

        .wechat-box {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .qrcode-box {
            width: 100%;
            height: 100%;
            text-align: center;
            position: relative;

            .qrCode {
              width: 296px;
              height: 296px;
              margin: 0 auto;
            }

            .qrCode-tips {
              width: 296px;
              height: 296px;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              cursor: pointer;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              background: rgba(255, 255, 255, .98);

              .el-icon-warning-outline {
                font-size: 60px;
                color: #F56C6C;
                margin-bottom: 20px;
              }
            }
          }

          .agree {
            text-align: center;
            transform: scale(1.2);

            a {
              color: $text-color-active;

              &:hover {
                color: $text-color-active !important;
              }
            }
          }
        }

      }
    }
  }

  .middle {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    .intro-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100px;
        height: 80px;
      }

      h2 {
        font-size: 24px;
        font-weight: bold;
        color: #3B3D49;
      }
    }
  }

  .bottom {
    width: 100%;
    margin: 30px 0;
    text-align: center;

    .btn-to-register {
      width: 280px;
      height: 46px;
      border-radius: 23px;
      background-color: $base;
      color: #FFF;
      font-size: 22px;
      cursor: pointer;
      outline: none;
      border: none;
    }
  }

}

.agreement {
  height: 70px;
  line-height: 70px;
  text-align: center;
}

@media screen and (min-width: 1800px) {
  .login-container {
    margin: 30px auto;
  }
}

/* 进入动画 */
.form-enter-active,
.form-leave-active {
  transition: opacity .2s;
}

/* 离开动画 */
.form-enter,
.form-leave-to {
  opacity: 0;
}
</style>
