import {reqUserLogin} from "@/api";
import axios from "axios";
import md5 from 'js-md5'
const state = {
    code: '',
    token: localStorage.getItem('TOKEN'),
	client_id:'',
    userInfo: {}
};
const mutations = {
    GETCODE(state, code) {
        state.code = code;
    },
    USERLOGIN(state, token) {
        state.token = token;
    },
    GETUSERINFO(state, userInfo) {
        state.userInfo = userInfo;
    },
    CLEAR(state) {
        state.userInfo = {};
        state.token = '';
        localStorage.removeItem('TOKEN');
    }
};
const actions = {
    //获取验证码
    async getCode({commit}, phone) {
        let result = await reqGetCode(phone);
        if(result.code == 200) {
            commit('GETCODE', result.data);
            return 'ok';
        } else {
            return Promise.reject(new Error('fail'));
        }
    },
    async userRegister({commit}, user) {
        let result = await reqUserRegister(user);
        if(result.code == 200) {
            return 'ok';
        } else {
            return Promise.reject(new Error('fail'));
        }
    },
    async userLogin({commit}, user) {
        const fn = async (result) => {
            let res = await axios({
                url: `${result.data.redirect_uri}?code=${result.data.code}`,
                method: 'get'
            });
            let data = res.data.data;
            if(res.data.code !== 200){
                return Promise.reject({
                    type: 'error',
                    state: 'notOk',
                    message: '系统异常'
                })
            }
            localStorage.setItem('TOKEN', data.token);
            commit("USERLOGIN", data.token);
            commit("GETUSERINFO", data);
            localStorage.setItem('user', JSON.stringify(data));
            const client_id = localStorage.getItem('client_id');
            //基座直接登录首页
            if (process.env.VUE_APP_CLIENT_ID === client_id) {
                return Promise.resolve({
                    type: 'success',
                    state: 'ok',
                    message: '登录成功'
                });
            }
            //否则登录三方应用
            const loginData = {
                grantType: 'business_token',
                token: localStorage.getItem('TOKEN')
            };
            const jumpRes = await reqUserLogin(loginData, client_id);
            if (jumpRes.code === 200) {
                window.location.href = `${jumpRes.data.redirect_uri}?code=${jumpRes.data.code}`;
                return Promise.resolve({
                    type: 'success',
                    state: 'not_jump',
                    message: '登录成功'
                });
            } else {
                return Promise.resolve({
                    type: 'warning',
                    state: 'ok',
                    message: jumpRes.msg + ':跳转到首页'
                });
            }
            //location.replace(`${result.data.redirect_uri}?code=${result.data.code}`);
        };
        if (user.grantType === 'wx') {
            return fn({...user});
        } else {
            if(user.grantType === 'password'){
                user.password = md5(user.password)
            }
            let result = await reqUserLogin(user);
            if (+result.code === 200) {
                return fn(result);
            } else {
                return Promise.resolve({
                    type: 'error',
                    state: 'not_jump',
                    message: result.msg
                });
            }
        }
    },
    async getUserInfo({commit}) {
        let result = await reqUserInfo();
        if(result.code == 200) {
            commit('GETUSERINFO', result.data);
            return 'ok';
        } else {
            return Promise.reject(new Error('fail'));
        }
    },
    async userLogout({commit}) {
        let result = await reqLogout();
        if(result.code == 200) {
            commit('CLEAR')
            return 'ok';
        } else {
            return Promise.reject(new Error('fail'));
        }
    }
};
const getters = {};
export default {
    state,
    mutations,
    actions,
    getters
}
