import VueRouter from 'vue-router';

import routes from "@/router/routes";
import store from '@/store/index';
// import slideToClickedSlide from "swiper/src/components/core/slide/slideToClickedSlide";

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
/*VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}*/
//重写push|replace
/*VueRouter.prototype.push = function(location, resolve, reject) {
    if(resolve && reject) {
        originalPush.call(this, location, resolve, reject);
    } else {
        originalPush.call(this, location, () => {}, () => {});
    }
}
VueRouter.prototype.replace = function(location, resolve, reject) {
    if(resolve && reject) {
        originalReplace.call(this, location, resolve, reject);
    } else {
        originalReplace.call(this, location, () => {}, () => {});
    }
}*/

let router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		return {
			y: 0
		}
	}
});

router.beforeEach(async (to, from, next) => {
	//to: 可以获取到你要跳转到哪个路由信息
	//from: 可以获取到你从哪个路由而来的信息
	//next: 放行函数 next(path)放行到指定的路由
	//用户登录了，才会有token，未登录一定不会有token
	let token = localStorage.getItem('TOKEN')
	let user = JSON.parse(localStorage.getItem('user'))
	store.state.user.userInfo = user
	next()
	return
	if (token) {
		//用户已经登录了还想去login
		if (to.path == '/login' || to.path == '/register') {
			next('/home');
		}
	} else {
		next(`/login/:${process.env.VUE_APP_CLIENT_ID}`)
	}
});

export default router;